
ul{
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap:nowrap;
}

.logostyle{
    height: 5rem;
    width: 9rem;
}
a{
    color: black;
    text-decoration: none;

}
a:hover{
    cursor:pointer;
    color:burlywood
}
.navbar{
    position: fixed;
}


.navlinks{
    font-size: 1rem
}
