.whoarewediv{
    margin:5rem;
    background: linear-gradient(#292c35,#8e785e);
    color: white;
    padding: 1rem;
}
.whowearedivport>h4{
    line-height: 3em  
}
.whowearediveng>h4{
    line-height: 3em
}
.whowearedivport{
    margin-top: 5rem
}

thead{
    width:80%
}